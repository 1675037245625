import React from 'react';
import { Layout, SEO } from '../components';
import { Link } from 'gatsby';

const library = () => {
  // const [content, setContent] = useState([]);
  // const [itemPruchase] = useState(0);
  // const dispatch = useDispatch();

  // async function allPurchase() {
  // const purchase = await api.itemPurchase();
  //   setContent(purchase?.content || []);
  //   setPurchase(purchase? || 0);
  // }
  return (
    <Layout>
      <SEO title='Library' />
      <div id='para'>
        <h1>Your Library</h1>
        <br />
        <h2 id='learn'>Envoy Learning!!</h2>
      </div>
      <div id='container'>
        <table className='w-full mb-2'>
          <thead>
            <tr>
              <th className='p-1 text-left'>PDF</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className='border-t'>
              <td className='p-1'>item Name list (backend)</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Link to='/profile' id='btn'>
        Return to Your Profile
      </Link>
      ;
    </Layout>
  );
};
export default library;
